import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>Black Sheep</h1>
    <h2>Excavating and Transport</h2>
    <p> 60650 Nw Wilcox Dr </p>
    <p>Timber, OR 97144</p>
    <p>971-330-4098</p>
  </Layout>
)

export default IndexPage
